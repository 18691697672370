<template>
  <div class="nav-bar">
    <div class="left row flex-center"><slot name="left"></slot></div>
    <div class="center"><slot name="center"></slot></div>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
  //import xxx from 'components/content/'
  export default {
    name: 'NavBar'
  }
</script>

<style scoped>
  .nav-bar{
    display: flex;
    height: 44px;
    line-height: 44px;
    text-align: center;
    box-shadow: 0 1px 1px rgba(100, 100, 100, .1);
    background-color: var(--theme-background);
    font-weight: 500;
    color: var(--theme-color);
    font-size: .426667rem;
  }
  .left,.right{
      width: 60px;
      height: 44px;
  }
  .center{
      flex: 1;
  }
</style>
