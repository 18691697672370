import {request} from './request'

export function product_categories() {
    return request({
        url: '/product_categories'
    })
}
export function goods_search(keyword,page=1,size=20) {
    return request({
        url: '/pinduoduo/goods_search',
        params: {
            keyword,
            page,
            size
        }
    })
}
//igou优品轮播图
export function ad_pictures() {
    return request({
        url: '/ad_pictures'
    })
}
//优品系统公告
export function announcements() {
    return request({
        url: '/announcements'
    })
}
//优品推荐商品
export function pingduoduo_top_goods(page=1,size=20) {
    return request({
        url: '/pinduoduo/top_goods',
        params: {
            page,
            size
        }
    })
}