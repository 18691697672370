<template>
  <div>
    <img class="headimg" :src="image_url" alt="" v-if="image_url">
    <grid-view :cols="3" :lineSpace="15" :v-margin="20" v-if="subcategories">
      <div class="item" v-for="(item, index) in subcategories" :key="index" @click="onDetail(item.name)">
        <img class="item-img" :src="item.image_url" alt="">
        <div class="item-text">{{item.name}}</div>
      </div>
    </grid-view>
  </div>
</template>

<script>
  import GridView from '@/components/common/gridView/GridView'

  export default {
    name: "TabContentCategory",
    components: {
      GridView
    },
    props: {
      image_url: {
        type: String,
        default: ''
      },
      subcategories: {
        type: Array,
        default() {
          return []
        }
      }
    },
    methods: {
      onDetail(name) {
        //this.$router.push('/search')
        this.$router.push({                        
                        path: '/search',                        
                        query: { q: name } 
                    })
      }
    }
  }
</script>

<style scoped>
.headimg{
  width: 98%;
}
  .panel img {
    width: 100%;
  }

  .item {
    text-align: center;
    font-size: 12px;
  }

  .item-img {
    width: 60%;
  }

  .item-text {
    margin-top: .106667rem;
  }
</style>
