<template>
  <div id="category">
    <nav-bar class="nav-bar"><div slot="center">分类</div></nav-bar>
    <div class="content">
      <tab-menu :categories="categories"
                @selectItem="selectItem" :currentIndex="currentIndex"></tab-menu>
      
      <scroll id="tab-content" >
        <div>
          <tab-content-category :image_url="image_url" :subcategories="secondary_categories"></tab-content-category>
        </div>
      </scroll>
    </div>
  </div>
</template>

<script>
  
  import NavBar from '@/components/common/navbar/NavBar'
  import Scroll from '@/components/common/scroll/Scroll'

  import TabMenu from './childComps/TabMenu'
  import TabContentCategory from './childComps/TabContentCategory'

  import {product_categories} from 'network/home'

  export default {
    name: "Category",
    data(){
      return {
        categories: [],
        secondary_categories: [],
        categoryData: {},
        currentIndex: 0,
        image_url: ''
      }
    },
    created() {
      console.log('this.$route.query.currentTabIndex='+this.$route.query.currentTabIndex)
      if(this.$route.query){
        this.currentIndex = this.$route.query.currentTabIndex == -1 ? 0 : this.$route.query.currentTabIndex;
      }else {
        this.currentIndex = 0;
      }
      this.get_product_categories();
    },
    computed: {
		  showSubcategory() {
        if(this.categories){
          return this.categories[this.currentIndex].secondary_categories;
        }else{
          return [];
        }
        
        
      }
    },
    methods: {
      /**
       * 事件响应相关的方法
       */
      selectItem(index) {
        this.currentIndex = index;
        this.image_url = this.categories[index].main_image_url;
        this.secondary_categories = this.categories[index].secondary_categories;
      },
      //加载数据
      get_product_categories(){
        product_categories().then(res => {
          this.categories = res.data
          if(res.data.length>0) {
            this.secondary_categories = res.data[this.currentIndex?this.currentIndex:0].secondary_categories;
            this.image_url = res.data[this.currentIndex?this.currentIndex:0].main_image_url;
          }
        })
      },
    },
    components: {
      NavBar,
      TabMenu,
      Scroll,
      TabContentCategory
    },
    
	}
</script>

<style scoped>
  #category {
    height: 100vh;
    background-color: #fff;
    z-index: 1;
  }
  .nav-bar{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    bottom: 49px;
    display: flex;
    z-index: 1;
  }
  #tab-content {
    height: 100%;
    flex: 1;
    background-color: #fff;
  }
</style>
